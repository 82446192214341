<template>
  <HeaderComponent />
  <router-view />
  <FooterPhoneComponent />
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterPhoneComponent from "./components/FooterPhoneComponent.vue";
export default {
  name: "app",
  components: { HeaderComponent, FooterPhoneComponent },
};
</script>

<style lang="scss">
@import './assets/style/variables';
#app {
  font-family: paralucent, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
