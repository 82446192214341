<template>
  <footer>
    <p>© {{ years }} Meedev</p>
    <router-link to="/mentions">Mentions légales & Politique de confidentialité</router-link>
  </footer>
</template>

<script>
export default {
  name: "FooterPhoneComponent",
  data() {
    return {
      years: "",
    };
  },
  mounted() {
    const date = new Date();
    this.years = date.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/variables";

footer {
  height: $phone-footer-height;
  color: $light-blue;
  font-weight: $light;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;

  a {
    color: white;
    text-decoration: none;
  }
}
</style>