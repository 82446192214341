<template>
  <header>
    <router-link class="logo" @click="navBar = false" to="/"
      ><img class="logo" src="../assets/images/svg/new-ws-green-bis.svg" alt="logo de meedev"></router-link
    >
    <font-awesome-icon
      @click="toggleNavBar"
      class="nav-icon"
      icon="fas fa-bars"
      size="2x"
    />
    <nav
      id="phone-nav"
      :class="'phone' + ' ' + (navBar ? 'active' : 'inactive')"
    >
      <router-link @click="toggleNavBar" to="/sea">Sites & applications web</router-link>
      <router-link @click="toggleNavBar" to="/maintenance">Maintenance</router-link>
      <router-link @click="toggleNavBar" to="/reference">Nos références</router-link>
      <router-link @click="toggleNavBar" to="/contact">Contact</router-link>
    </nav>

    <nav class="desktop">
      <router-link to="/sea">Sites & applications web</router-link>
      <router-link to="/maintenance">Maintenance</router-link>
      <router-link to="/reference">Nos références</router-link>
      <router-link to="/contact">Contact</router-link>
    </nav>
  </header>
</template>
  
  <script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      navBar: false,
    };
  },
  methods: {
    toggleNavBar() {
      this.navBar = !this.navBar;
    },
  },
  mounted() {
    window.addEventListener("wheel", function () {
      this.document.getElementById("phone-nav").classList.remove("active");
    });
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../assets/style/variables";

header {
  width: calc(100% - ($phone-margin-header * 2));
  height: $phone-header-height;
  padding: $phone-margin-header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $light-blue;
  position: absolute;
  top: 0;
  z-index: 2;

  .logo {
    height: 30px;
  }

  @media screen and (min-width: 1366px) {
    .nav-icon {
      display: none;
    }

    .phone {
      display: none !important;
    }

    .desktop {
      display: flex !important;
    }

    width: calc(100% - ($desktop-gutter * 2));
  }

  .logo {
    text-decoration: none;
    color: $light-blue;
  }

  nav.phone {
    width: 100vw;
    min-height: calc(100vh - $phone-header-full-height);
    top: $phone-header-full-height;
    background-color: $light-blue;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scaleY(0);
    transition: 400ms ease-in-out;
    transform-origin: center top;

    a {
      padding: $phone-margin-header;
      text-decoration: none;
      font-size: $phone-font-size-aux-title;
      font-weight: $light;
      color: $dark-blue;
    }

    &.active {
      transform: scaleY(1);
    }
  }

  nav.desktop {
    display: none;

    a {
      color: $light-blue;
      font-weight: $extra-light;
      font-size: $desktop-font-size-text;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: $phone-margin-normal;
      }

      &:hover {
        color: $green;
      }
    }
  }
}

// .menu-deroulant {
//   position: relative;
//   margin-right: $phone-padding-box;
//     width: 195px !important;
//     text-align: left;

//   &:hover {
//     ul {
//       transform: translateY(0);
//       opacity: 1;

//       li {
//         &:nth-child(2) {
//           transform: translateY(0);
//         }

//         &:nth-child(3) {
//           transform: translateY(0);
//         }
//       }
//     }
//   }

//   ul {
//     position: absolute;
//     top: 10px;
//     width: 180px;
//     transform: translateY(calc(-100% - 5px));
//     height: 20px;
//     transition: 200ms ease-in-out;
//     opacity: 0;
//     padding-left: $phone-medium-padding;

//     li {
//       text-align: left;
//       list-style-type: none;
//       height: 20px;
//       transition: 200ms ease-in-out;
//       padding: $phone-small-padding 0;

//       &:nth-child(2) {
//         transform: translateY(-100%);
//       }

//       &:nth-child(3) {
//         transform: translateY(-200%);
//       }

//       a:hover {
//         color: $green;
//       }
//     }
//   }
// }
</style>