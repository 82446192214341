<template>
  <main class="home">
    <section class="above-the-fold">
      <img src="../assets/images/jpg/background.jpg" alt="" />
      <h1>Meedev - Create & innovate - Agence de développement web</h1>
      <h2>Créez votre place sur internet</h2>
      <h3>& <span class="changing-text"></span></h3>
      <ButtonComponent @click="contact" text="Nous contacter" />
      <img class="atf-line" src="../assets/images/svg/atf-line.svg" alt="" />
    </section>

    <section class="prestation">
      <div class="container">
        <lottie-player
          class="desktop"
          src="https://assets5.lottiefiles.com/packages/lf20_w51pcehl.json"
          background="transparent"
          speed="1"
          style="width: 400px; height: 400px"
          loop
          autoplay
        ></lottie-player>
        <article
          class="prestation-box"
          v-for="prestation in prestations"
          :key="prestation"
        >
          <h3>{{ prestation.title }}</h3>
          <p>{{ prestation.text }}</p>
          <router-link :to="prestation.path">{{ prestation.link }}</router-link>
        </article>
      </div>
      <article class="prestation-project">
        <h3>Votre projet et nous <span class="title-underline"></span></h3>
        <p>
          Nous mettons tout en œuvre pour faire de votre
          <span class="special-text">projet</span> une
          <span class="special-text">réalité</span> et nous vous accompagnons de
          sa <span class="special-text">conception</span> à sa
          <span class="special-text">réalisation</span>.
        </p>
        <ButtonComponent
          color="light-blue"
          backgroundColor="green"
          text="Nos projets"
          class="mobile"
        />
      </article>
    </section>

    <section class="us">
      <div class="background"></div>
      <article>
        <h3>Passionnés par notre <span class="special-text">métier</span></h3>
        <div class="text-box">
          <p>
            Nous visons l'excellence pour chacun de nos clients, en assurant une
            transparence complète entre vous et nos équipes durant toute la
            durée de votre projet.
          </p>
          <div class="desktop vertical-divider"></div>
          <p>
            Nous avons l’habitude de travailler sur des projets de start-up,
            projets personnels, ou avec des enjeux professionnels. Notre méthode
            de travail s’adapte à toutes les situations.
          </p>
        </div>
        <div class="partner">
          <!-- <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_ugbckvu9.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px"
          loop
          autoplay
        ></lottie-player> -->
        <a href="https://partnernetwork.ionos.fr/partner/gabriel.voissiere?origin=PartnerBadge" rel="nofollow"> <img src="https://images-2.partnerportal.ionos.fr/items/59a471cd-ae19-4200-90b7-7edecb04d0fa/profiles/32e61d5b-cfc8-4a0b-ad38-0dcd0aead19a/badges/normal_blue_eco" alt="IONOS - Partenaire officiel"> </a>
        </div>
      </article>

      <article>
        <h3>notre <span class="special-text">expertise</span></h3>
        <div class="text-box">
          <p>
            Nous développons pour vous des sites, des logiciels et des
            applications web et mobiles qui peuvent répondre à des utilisations
            simples comme complexes.
          </p>
          <div class="desktop vertical-divider"></div>
          <p>
            Nous vous conseillons sur votre stratégie technique grâce à notre
            expérience et votre vision du projet. Stack technique, SEO, UI/UX et
            performance.
          </p>
        </div>
      </article>
    </section>

    <!-- <section class="testimonial">
      <h3>Témoignages</h3>
      <div class="background"></div>
      <div class="testimonial-box">
        <article v-for="temoignage in temoignages" :key="temoignage">
          <p>{{ temoignage.text }}</p>
          <div class="author">
            <img
              :src="require(`@/assets/images/${temoignage.img}`)"
              class="logo"
            />
            <div class="name-role">
              <p class="special-text">- {{ temoignage.name }}</p>
              <p>{{ temoignage.role }}</p>
            </div>
          </div>
        </article>
      </div>
    </section> -->
  </main>
</template>

<script>
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
export default {
  name: "HomeView",
  data() {
    return {
      prestations: [
        {
          title: "Site internet sur mesure",
          text: "Démarquez-vous auprès de vos clients avec un site web fait sur mesure et qui vous correspond.",
          link: "En savoir plus >",
          path: "/sea",
        },
        {
          title: "Application web évolutive",
          text: "Une application web pouvant évoluer selon vos besoins. Mêlent fiabilité et accessibilité.",
          link: "En savoir plus >",
          path: "/sea",
        },
        {
          title: "Maintenance h24 et 7j/7",
          text: "Maintenance de votre site 24 heures sur 24, 7j/7. Mises à jour de sécurité et modifications.",
          link: "En savoir plus >",
          path: "/maintenance",
        },
      ],
      temoignages: [
        {
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, ipsum corporis repellendus iure cupiditate earum vero obcaecati laborum, ea quam officia nesciunt, sequi expedita dignissimos! Aliquid dolores delectus laborum voluptates?",
          img: "jpg/background.jpg",
          name: "Nom de la personne",
          role: "",
        },
        {
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, ipsum corporis repellendus iure cupiditate earum vero obcaecati laborum, ea quam officia nesciunt, sequi expedita dignissimos! Aliquid dolores delectus laborum voluptates?",
          img: "jpg/background.jpg",
          name: "Nom de la personne",
          role: "",
        },
        {
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, ipsum corporis repellendus iure cupiditate earum vero obcaecati laborum, ea quam officia nesciunt, sequi expedita dignissimos! Aliquid dolores delectus laborum voluptates?",
          img: "jpg/background.jpg",
          name: "Nom de la personne",
          role: "",
        },
      ],
    };
  },
  components: { ButtonComponent },
  methods: {
    contact() {
      this.$router.push({ name: "contact" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/variables";

.home {
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  color: $light-blue;

  section {
    margin: 0 $phone-gutter;
  }

  @media screen and (min-width: 1366px) {
    section {
      margin: 0 $desktop-gutter;
    }

    .desktop {
      display: block !important;
    }

    .mobile {
      display: none !important;
    }
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .above-the-fold {
    min-height: calc(100vh - $phone-header-full-height);
    margin-top: $phone-first-section-top-margin;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    img:first-child {
      position: absolute;
      width: 100vw;
      height: 100vh;
      bottom: 0;
      object-fit: cover;
      z-index: -1;
    }

    @keyframes animate {
      0% {
        background-position: -500%;
      }
      100% {
        background-position: 500%;
      }
    }

    @keyframes aitf {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 50% -100%;
      }
    }

    h1 {
      margin-top: calc($phone-margin-normal * 8);
      font-weight: $medium;
    }

    h2 {
      font-size: $phone-font-size-title;
      font-weight: 500;
      text-transform: uppercase;
      font-weight: $medium;

      // background: url(../assets/images/jpg/animated-text-fill.png) repeat-y;
      // -webkit-background-clip: text;
      // background-clip: text;

      // /* Animate Background Image */

      // -webkit-text-fill-color: transparent;
      // -webkit-animation: aitf 50s linear both;

      // /* Activate hardware acceleration for smoother animations */

      // -webkit-transform: translate3d(0, 0, 0);
      // -webkit-backface-visibility: hidden;

      @media screen and (min-width: 1366px) {
        font-size: $desktop-font-size-title;
      }
    }

    h3 {
      font-size: $phone-font-size-subtitle;
      font-weight: 400;
      margin-bottom: $phone-margin-medium;
      font-weight: $extra-light;
      color: $green;

      @media screen and (min-width: 1366px) {
        font-size: $desktop-font-size-aux-title;
      }

      @keyframes textChange {
        0% {
          content: "augmentez votre présence digital";
        }
        25% {
          content: "démarquez vous aupres de vos clients";
        }
        50% {
          content: "boostez votre communication";
        }
        75% {
          content: "facilitez la vie de votre entreprise";
        }
        100% {
          content: "pensez au future";
        }
      }

      .changing-text::before {
        content: "augmentez votre présence digital";
        animation: textChange 11000ms 1000ms ease-in-out alternate infinite both;
      }
    }

    .atf-line {
      position: absolute;
      bottom: 0;
      z-index: 0;
    }
  }

  .prestation {
    padding: calc($phone-padding-box * 2) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .container {
      article:nth-child(2),
      article:nth-child(3) {
        margin-bottom: $phone-margin-medium;
      }

      @media screen and (min-width: 1366px) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        article:nth-child(2),
        article:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }

    &-box {
      padding: 28px;
      background-color: $light-blue;
      color: $dark-blue;
      border-radius: $phone-radius;
      text-align: justify;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: 200ms ease-in-out;
      border: 2px solid transparent;

      &:hover {
        transform: scale(1.1);
      }

      @media screen and (min-width: 1366px) {
        width: 15%;
        height: 200px;
        text-align: left;
      }

      h3 {
        font-size: $phone-font-size-aux-title;
        text-align: left;
      }

      p {
        margin: $phone-margin-normal 0;
        font-weight: $light;

        @media screen and (min-width: 1366px) {
          font-size: $desktop-font-size-text;
        }
      }

      a {
        color: $green;
        text-decoration: none;
      }
    }

    &-project {
      margin-top: calc($phone-margin-medium * 2);

      h3 {
        font-size: $phone-font-size-aux-title;
        text-transform: uppercase;
        text-align: left;
        position: relative;
        margin-bottom: $phone-margin-normal;

        .title-underline {
          width: 100%;
          height: 2px;
          background-color: $green;
          position: absolute;
          bottom: -2px;
          left: 0;

          @media screen and (min-width: 1366px) {
            width: 20%;
          }
        }
      }

      p {
        text-align: justify;
        margin-bottom: $phone-margin-medium;
        font-weight: $light;

        @media screen and (min-width: 1366px) {
          font-size: $desktop-font-size-text;
          font-weight: $extra-light;
        }
      }
    }
  }

  .us {
    color: $dark-blue;
    padding: $phone-padding-box 0;
    position: relative;
    z-index: 2;

    .partner {
      margin-top: $phone-margin-large;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 50px;

      img {
        width: 200px;
      }
    }

    @media screen and (min-width: 1366px) {
      padding: calc($phone-padding-box * 3) 0;
    }

    .background {
      width: 100vw;
      height: 100%;
      background-color: $light-blue;
      position: absolute;
      bottom: 0;
      left: -$phone-gutter;
      z-index: -1;

      @media screen and (min-width: 1366px) {
        left: -$desktop-gutter;
      }
    }

    article {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        h3 {
          margin-top: calc($phone-margin-large);
        }
      }

      h3 {
        text-transform: uppercase;
        font-size: $phone-font-size-aux-title;
        margin-bottom: calc($phone-margin-medium - 10px);
      }

      p {
        font-weight: $light;
        text-align: justify;
      }

      .text-box {
        p:last-child {
          margin-top: calc($phone-margin-medium - 10px);

          @media screen and (min-width: 1366px) {
            margin-top: 0;
          }
        }

        @media screen and (min-width: 1366px) {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            width: 40%;
          }

          .vertical-divider {
            width: 1px;
            height: 50px;
            background-color: $green;
            margin: 0 $desktop-margin-box;
          }
        }
      }
    }
  }

  .testimonial {
    color: $dark-blue;
    padding: $phone-padding-box 0;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 1366px) {
      padding: calc($phone-padding-box * 3) 0;
    }

    .background {
      width: 100vw;
      height: calc(100% + 5px);
      background-color: $light-blue;
      position: absolute;
      bottom: 0;
      left: -$phone-gutter;
      z-index: -1;

      @media screen and (min-width: 1366px) {
        left: -$desktop-gutter;
      }
    }

    h3 {
      text-transform: uppercase;
      font-size: $phone-font-size-aux-title;
      margin-bottom: $phone-margin-medium;
    }

    &-box {
      @media screen and (min-width: 1366px) {
        display: flex;
        justify-content: space-between;
      }

      article {
        background-color: $dark-blue;
        color: $light-blue;
        padding: $phone-padding-box;
        border-radius: $phone-radius;

        @media screen and (min-width: 1366px) {
          width: 28%;
        }

        &:nth-child(2) {
          margin: $phone-margin-medium 0;

          @media screen and (min-width: 1366px) {
            margin: 0;
          }
        }

        p {
          text-align: justify;
          font-weight: $extra-light;
        }

        .author {
          margin-top: $phone-margin-normal;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 60px;
            width: 60px;
            border-radius: 100%;
          }

          .name-role {
            p {
              letter-spacing: 2px;

              &:first-child {
                margin-bottom: calc($phone-margin-normal / 2);
              }
            }
          }
        }
      }
    }
  }
}
</style>