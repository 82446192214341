<template>
  <div class="button">
    <button
      id="button"
      :class="
        'color-' +
        color +
        ' ' +
        'background-color-' +
        backgroundColor +
        ' ' +
        size +
        ' ' +
        status
      "
      :disabled="disable"
    >
      {{
        status === "error"
          ? errorText
          : status === "success"
          ? succesText
          : text
      }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: {
      type: String,
      default: "Text here",
    },
    errorText: {
      type: String,
      default: "Text here",
    },
    succesText: {
      type: String,
      default: "Text here",
    },
    disable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal",
    },
    color: {
      type: String,
      default: "light-blue",
    },
    backgroundColor: {
      type: String,
      default: "dark-blue",
    },
    status: {
      type: String,
      default: "normal",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/variables";

button {
  border-radius: $phone-radius;
  border: none;
  font-size: $phone-font-size-text;
  position: relative;
  z-index: 1;
  transition: 200ms;

  &:hover {
    cursor: pointer;
  }
}

.normal {
  width: $phone-width;
  height: $phone-height;
}

.medium {
  width: calc($phone-width * 1.5);
  height: calc($phone-height * 1.5);
}

.large {
  width: calc($phone-width * 2);
  height: calc($phone-height * 2);
}

.color-green {
  color: $green;
}

.color-light-blue {
  color: $light-blue;
}

.color-dark-blue {
  color: $dark-blue;
}

.background-color-green {
  background-color: $green;

  &:hover {
    background-color: transparent;
    border: 1px solid $green;
  }
}

.background-color-light-blue {
  background-color: $light-blue;
}

.background-color-dark-blue {
  background-color: $dark-blue;

  &:hover {
    background-color: $green;
  }
}

.error {
  background-color: $error;

  &:hover {
    background-color: transparent;
    border: 1px solid $error;
  }
}
</style>