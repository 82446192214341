import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sea',
    name: 'site_et_application',
    component: () => import('../views/SiteAppView.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/MaintenanceView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/reference',
    name: 'reference',
    component: () => import('../views/ProjectView.vue')
  },
  { 
    path: "/mentions", 
    component: () => import('../views/MentionPolitique.vue')
  },
  { 
    path: "/:pathMatch(.*)*", 
    component: () => import('../views/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
